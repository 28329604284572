import React, {useEffect, useState} from 'react';
import UserSteps from "./steps/UserSteps.jsx";
import DeliveryTypeSteps from "./steps/DeliveryTypeSteps.jsx";
import CalculateFieldSteps from "./steps/CalculateFieldSteps.jsx";

const dataSendToJson = {
    tempmode: 'ТемпературныйРежим_БезРежима',
    startCity: '',
    finishCity: '',
    startCityName: '',
    finishCityName: '',
    pallet: 0,
    weightMain: 0,
    bulkMain: 0,
    forwardingShipment: 'false',
    forwardingDestination: 'false',
    express: 'false',
    insurance: 'false',
    goodPrice: 0,
    TypeTransportation: '',
    NameUser: '',
    LastName: '',
    Surname: '',
    Phone: '',
    Email: '',
    INN: '',
    dateClient: '',
    CompanyName: '',
}

function Calculate({Directions, cityStartParam, cityFinishParam, tempmodeParam}) {
    const [refresh, setRefresh] = useState(false)
    const [ListDirection, setListDirections] = useState([])
    const [listCalculateResult, setListCalculateResult] = useState([])
    const [showDelivery, setShowDelivery] = useState(false)
    const [messages, setMessages] = useState([])
    const [listCityStart, setListCityStart] = useState([])
    const [listCityFinish, setListCityFinish] = useState([])
    const [showResult, setShowResult] = useState(false)
    const [textResult, setTextResult] = useState('')
    const [showUserData, setShowUserData] = useState(false)
    const [listTempMode, setListTempMode] = useState([])
    const listTempModeVariant = {
        ТемпературныйРежим_4: '+4',
        ТемпературныйРежим_18: '-18',
        ТемпературныйРежим_БезРежима: 'Без режима'
    }
    const [cityStart, setCityStart] = useState(null)
    const [cityFinish, setCityFinish] = useState(null)
    const [activeTariff, setActiveTariff] = useState(false)

    function getListTariffs() {
        setListDirections(Directions)
    }

    function filterListTariffs(type = 'setAll') {
        let currentCityStart = cityStart

        if (type === 'setEnd' || type === 'setAll') {
            const cityStartList = []
            ListDirection?.forEach((city) => {
                if (city.guidFinish === cityFinish?.guidFinish || cityStart === null) {
                    const findValItemEnd = cityStartList.find((itemCityEnd) => itemCityEnd.guidStart === city.guidStart)
                    if (findValItemEnd === undefined) {
                        cityStartList.push(city)
                    }
                }
            })

            let index = 0;
            if (type === 'setAll') {
                if (cityStartParam !== '') {
                    index = cityStartList.findIndex(city => city.guidStart === cityStartParam);
                }
                setCityStart(cityStartList[index])
            }

            currentCityStart = cityStartList[index]
            setListCityStart(cityStartList)
        }

        if (type === 'setStart' || type === 'setAll') {
            const cityEndList = []
            ListDirection?.forEach((city) => {
                if (city.guidStart === currentCityStart?.guidStart) {
                    const findValItemEnd = cityEndList.find((itemCityEnd) => itemCityEnd.guidFinish === city.guidFinish)
                    if (findValItemEnd === undefined) {
                        cityEndList.push(city)
                    }
                }
            })

            setListCityFinish(cityEndList)

            let index = 0;
            if (type === 'setAll') {
                if (cityStartParam !== '') {
                    index = cityEndList.findIndex(city => city.guidFinish === cityFinishParam);
                }
            }

            setCityFinish(cityEndList[index])
        }
    }

    function getTempModeList() {
        const tempList = []
        ListDirection?.forEach((city) => {
            if ((city.guidFinish === cityFinish?.guidFinish || cityFinish === null) && (city.guidStart === cityStart?.guidStart || cityStart === null)) {
                const tempMode = tempList.find((itemTempmode) => itemTempmode.tempmode === city.tempmode)
                if (tempMode === undefined) {
                    tempList.push(city)
                }
            }
        })

        setListTempMode(tempList)
    }

    useEffect(() => {
        getListTariffs()
    }, [])

    useEffect(() => {
        getTempModeList()
    }, [cityFinish, cityStart, ListDirection])

    useEffect(() => {
        if (ListDirection.length > 0) {
            filterListTariffs('setAll')
        }
    }, [ListDirection])

    useEffect(() => {
        if (refresh) {
            filterListTariffs('setStart')
            setRefresh(false)
        }
    }, [cityStart])

    useEffect(() => {
        if (refresh) {
            filterListTariffs('setEnd')
            setRefresh(false)
        }
    }, [cityFinish])


    return (
        <div className="flex flex-col mx-6 max-w-7xl w-full">
            <div className="md:w-full mb-7 rounded-xl md:px-8 px-4 py-7 bg-white min-h-[480px] shadow-md">
                <h2 className="text-2xl 2xl:text-3xl font-medium mb-4 flex flex-row justify-between">
                    Оформить отправление
                    <div className="text-2xl 2xl:text-3xl font-medium">
                        {showUserData ? '3/3' : showDelivery ? '2/3' : '1/3'}
                    </div>
                </h2>
                <div className="border-b-2 mb-8 border-primary/40 w-full rounded-xl"></div>
                {!showUserData && !showResult && !showDelivery ?
                    <>
                        <CalculateFieldSteps cityStart={cityStart} setCityStart={setCityStart}
                                             setRefresh={setRefresh} setShowDelivery={setShowDelivery}
                                             setListCalculateResult={setListCalculateResult}
                                             setCityFinish={setCityFinish}
                                             setMessages={setMessages}
                                             dataSendToJson={dataSendToJson}
                                             cityFinish={cityFinish}
                                             listCityStart={listCityStart}
                                             listCityFinish={listCityFinish}
                                             cityStartParam={cityStartParam}
                                             cityFinishParam={cityFinishParam}
                                             tempmodeParam={tempmodeParam}
                                             listTempMode={listTempMode}
                                             listTempModeVariant={listTempModeVariant}
                                             messages={messages}/>
                    </> : false
                }
                <DeliveryTypeSteps showResult={showResult} messages={messages} dataSendToJson={dataSendToJson}
                                   setShowUserData={setShowUserData} setShowResult={setShowResult}
                                   showDelivery={showDelivery} setShowDelivery={setShowDelivery}
                                   showUserData={showUserData} setActiveTariff={setActiveTariff}
                                   activeTariff={activeTariff} listTempModeVariant={listTempModeVariant}
                                   listCalculateResult={listCalculateResult}/>
                <UserSteps showResult={showResult} setShowUserData={setShowUserData} messages={messages}
                           setMessages={setMessages} activeTariff={activeTariff} setShowResult={setShowResult}
                           setTextResult={setTextResult} dataSendToJson={dataSendToJson} showUserData={showUserData}
                           showDelivery={showDelivery} setShowDelivery={setShowDelivery}/>
                {
                    showResult ?
                        <p className="p-3 font-medium text-primary text-center">
                            {textResult}
                        </p>
                        : false
                }
            </div>
        </div>
    )
}

export default Calculate;